import { useEffect, useState } from "react";

const useScrollTracker = () => {
    const [scrollPosition, setScrollPosition] = useState(0);

    const updateScrollPosition = () => {
        const position = window.pageYOffset;
        setScrollPosition(position >= 0 ? position : 0); // clamp Safari's bounce offset
    };

    useEffect(() => {
        window.addEventListener("scroll", updateScrollPosition, {
            passive: true,
        });

        return () => {
            window.removeEventListener("scroll", updateScrollPosition);
        };
    }, []);

    return scrollPosition;
};

export default useScrollTracker;
