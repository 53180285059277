import React, { useCallback, useState } from "react";
import { graphql, StaticQuery } from "gatsby";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import "../style/main.scss";
import Helmet from "react-helmet";
import {
  AtolyemizDataContext,
  EventRegistrationContext,
  EventsDataContext,
  GeneralDataContext,
  HomepageDataContext,
  NewsDataContext,
  ToolsDataContext,
  ProductsDataContext,
} from "../context";
import EventRequestForm from "./EventRequestForm";

const DataFetcher = ({ children }) => (
  <StaticQuery
    query={graphql`
      {
        strapiAnasayfa {
          Giris__Kisa_Aciklama
          Hizmet_1__aciklama
          Hizmet_1__baslik
          Hizmet_2__aciklama
          Hizmet_2__baslik
          Hizmet_3__aciklama
          Hizmet_3__baslik
          etkinlikler_davet_yazisi
          haberler_davet_yazisi
          veda_mesaji
          yazili_resimli_satir_1 {
            aciklama
            baslik
            buton {
              baslik
              rota
            }
            resim {
              childImageSharp {
                gatsbyImageData(layout: CONSTRAINED, width: 700, height: 500)
              }
            }
          }
          yazili_resimli_satir_2 {
            aciklama
            baslik
            buton {
              baslik
              rota
            }
            resim {
              childImageSharp {
                gatsbyImageData(layout: CONSTRAINED, width: 700, height: 500)
              }
            }
          }
        }

        strapiAtolyemiz {
          destekciler_tesekkur_yazisi
          giris_aciklama_paragraf_1
          giris_aciklama_paragraf_2
          giris_baslik
          resimli_satir_1 {
            aciklama
            baslik
            resim {
              childImageSharp {
                gatsbyImageData(layout: CONSTRAINED)
              }
            }
          }
          resimli_satir_2 {
            aciklama
            baslik
            resim {
              childImageSharp {
                gatsbyImageData(layout: CONSTRAINED)
              }
            }
          }
          galeri {
            id
            url
          }
        }

        allStrapiHaberlers(
          sort: { fields: haber_yazilma_tarihi, order: DESC }
        ) {
          edges {
            node {
              haber_basligi
              haber_icerigi
              haber_yazilma_tarihi(locale: "tr", formatString: "DD MMMM YYYY")
              id
              slug
              haber_resmi {
                childImageSharp {
                  gatsbyImageData(
                    layout: CONSTRAINED
                    width: 730
                    height: 550
                    quality: 100
                  )
                }
              }
            }
          }
        }

        allStrapiEtkinliklers(sort: { fields: etkinlik_tarihi, order: DESC }) {
          edges {
            node {
              etkinlik_basligi
              etkinlik_aciklamasi
              etkinlik_tarihi(formatString: "DD MMMM YYYY", locale: "tr")
              unformattedDate: etkinlik_tarihi
              etkinlik_saati
              slug
              etkinlik_resmi {
                childImageSharp {
                  gatsbyImageData(height: 275, width: 300, layout: CONSTRAINED)
                }
              }
              iliskili_haber {
                slug
              }
            }
          }
        }

        allStrapiEkipmanlars {
          edges {
            node {
              ekipman_aciklamasi
              ekipman_aciklamasi_2
              ekipman_ismi
              id
              ekipman_resmi {
                childImageSharp {
                  gatsbyImageData(layout: CONSTRAINED)
                }
              }
            }
          }
        }

        allStrapiUrunlers {
          edges {
            node {
              urun_aciklamasi
              urun_aciklamasi_2
              urun_ismi
              id
              urun_resmi {
                childImageSharp {
                  gatsbyImageData(layout: CONSTRAINED)
                }
              }
              iliskili_haber {
                slug
              }
            }
          }
        }

        eventDates: allStrapiEtkinliklers(
          sort: { fields: etkinlik_tarihi, order: DESC }
        ) {
          edges {
            node {
              etkinlik_tarihi(formatString: "DD MMMM YYYY")
            }
          }
        }

        strapiSponsorlar {
          sponsor_1__link
          sponsor_2__link
          sponsor_3__link
          sponsor_4__link
          sponsor_1__logo {
            childImageSharp {
              gatsbyImageData(layout: CONSTRAINED, width: 128, height: 128)
            }
          }
          sponsor_2__logo {
            childImageSharp {
              gatsbyImageData(layout: CONSTRAINED, width: 128, height: 128)
            }
          }
          sponsor_3__logo {
            childImageSharp {
              gatsbyImageData(layout: CONSTRAINED, width: 128, height: 128)
            }
          }
          sponsor_4__logo {
            childImageSharp {
              gatsbyImageData(layout: CONSTRAINED, width: 128, height: 128)
            }
          }
          etkin
        }

        strapiSosyalMedya {
          facebook
          instagram
          linkedin
          twitter
        }

        strapiDestekciIsimleri {
          destekciler
        }

        strapiEkip {
          ekip
        }

        strapiEtkinlik {
          ek_talep_yazisi
        }
      }
    `}
    render={data => <Layout data={data}>{children}</Layout>}
  />
);

const Layout = ({ children, data }) => {
  const [isRequestFormOpen, setIsRequestFormOpen] = useState(false);

  const [selectedEvent, setSelectedEvent] = useState("");
  const selectEvent = useCallback(eventTitle => {
    setSelectedEvent(eventTitle);
  }, []);

  const combinedGeneralData = {
    ...data.strapiSosyalMedya,
    ...data.strapiDestekciIsimleri,
    ...data.strapiEkip,
    ...data.strapiSponsorlar,
    ...data.strapiEtkinlik,
    eventDates: data?.eventDates.edges.map(item => item.node.etkinlik_tarihi),
  };

  const openRequestForm = () => setIsRequestFormOpen(true);

  const closeRequestForm = () => setIsRequestFormOpen(false);

  return (
    <>
      <AtolyemizDataContext.Provider value={data?.strapiAtolyemiz}>
        <GeneralDataContext.Provider value={combinedGeneralData}>
          <HomepageDataContext.Provider value={data?.strapiAnasayfa}>
            <NewsDataContext.Provider value={data?.allStrapiHaberlers}>
              <EventsDataContext.Provider value={data?.allStrapiEtkinliklers}>
                <ToolsDataContext.Provider value={data?.allStrapiEkipmanlars}>
                  <ProductsDataContext.Provider value={data?.allStrapiUrunlers}>
                    <EventRegistrationContext.Provider
                      value={{
                        selectedEvent,
                        selectEvent,
                      }}
                    >
                      <Helmet>
                        <meta charSet="utf-8" />
                        <meta
                          name="description"
                          content="Atölye Ankara'nın resmi web sitesidir."
                        />
                        <html lang="tr" />
                        <title>Atölye Ankara</title>
                      </Helmet>

                      <Navbar openRequestForm={openRequestForm} />
                      <main>{children}</main>
                      <Footer />
                      <EventRequestForm
                        isOpen={isRequestFormOpen}
                        closeForm={closeRequestForm}
                      />
                    </EventRegistrationContext.Provider>
                  </ProductsDataContext.Provider>
                </ToolsDataContext.Provider>
              </EventsDataContext.Provider>
            </NewsDataContext.Provider>
          </HomepageDataContext.Provider>
        </GeneralDataContext.Provider>
      </AtolyemizDataContext.Provider>
    </>
  );
};

export default DataFetcher;
