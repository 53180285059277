import React, { useContext } from "react";
import {
  FaAngleUp,
  FaTwitter,
  FaLinkedinIn,
  FaFacebookF,
  FaInstagram,
} from "react-icons/fa";
import { GatsbyImage } from "gatsby-plugin-image";
import { GeneralDataContext } from "../../context";

const Footer = () => {
  const data = useContext(GeneralDataContext);

  const _backToTop = () => {
    window.scrollTo(0, 0);
  };

  return (
    <footer>
      <span className="back_to_top">
        <FaAngleUp size={35} onClick={() => _backToTop()} />
      </span>
      <div className="social-links">
        <a
          href={data?.twitter}
          aria-label="Twitter"
          target="_blank"
          rel="noopener noreferrer"
        >
          <FaTwitter size={30} />
        </a>
        <a
          href={data?.linkedin}
          aria-label="Linkedin"
          target="_blank"
          rel="noopener noreferrer"
        >
          <FaLinkedinIn size={30} />
        </a>
        <a
          href={data?.facebook}
          aria-label="Facebook"
          target="_blank"
          rel="noopener noreferrer"
        >
          <FaFacebookF size={30} />
        </a>
        <a
          href={data?.instagram}
          aria-label="Instagram"
          target="_blank"
          rel="noopener noreferrer"
        >
          <FaInstagram size={30} />
        </a>
      </div>

      {data.etkin === true && (
        <div className="sponsors_container">
          <a
            href={data?.sponsor_1__link}
            target="_blank"
            rel="noopener noreferrer"
          >
            <GatsbyImage
              image={data?.sponsor_1__logo?.childImageSharp.gatsbyImageData}
              className="sponsor_logo"
              alt="Etkinlikle alakalı bir görsel"
            />
          </a>

          <a
            href={data?.sponsor_2__link}
            target="_blank"
            rel="noopener noreferrer"
          >
            <GatsbyImage
              image={data?.sponsor_2__logo?.childImageSharp.gatsbyImageData}
              className="sponsor_logo"
              alt="Etkinlikle alakalı bir görsel"
            />
          </a>

          <a
            href={data?.sponsor_3__link}
            target="_blank"
            rel="noopener noreferrer"
          >
            <GatsbyImage
              image={data?.sponsor_3__logo?.childImageSharp.gatsbyImageData}
              className="sponsor_logo"
              alt="Etkinlikle alakalı bir görsel"
            />
          </a>

          <a
            href={data?.sponsor_4__link}
            target="_blank"
            rel="noopener noreferrer"
          >
            <GatsbyImage
              image={data?.sponsor_4__logo?.childImageSharp.gatsbyImageData}
              className="sponsor_logo"
              alt="Etkinlikle alakalı bir görsel"
            />
          </a>
        </div>
      )}

      <hr />
      <p className="copyright">
        Copyright &copy; {new Date().getFullYear()} -{" "}
        <a
          target="_blank"
          rel="noreferrer noopener"
          href="http://www.pisavunma.com.tr"
        >
          Pi Savunma
        </a>{" "}
        -{" "}
        <a
          target="_blank"
          rel="noreferrer noopener"
          href="https://erincpolat.com"
        >
          Erinç Polat
        </a>{" "}
        - Tüm Hakları Saklıdır
      </p>
    </footer>
  );
};

export default Footer;
