import React from "react";

export const GeneralDataContext = React.createContext();
export const HomepageDataContext = React.createContext();
export const EventsDataContext = React.createContext();
export const NewsDataContext = React.createContext();
export const AtolyemizDataContext = React.createContext();
export const ToolsDataContext = React.createContext();
export const ProductsDataContext = React.createContext();

export const EventRegistrationContext = React.createContext();