import React from "react";
import { BsArrowLeft, BsArrowRight } from "react-icons/bs";
import { navigate } from "gatsby";
import { COLORS } from "../../utils/variables";

const TextButton = ({
  text,
  className,
  style,
  targetRoute,
  routePayload,
  arrowAlwaysVisible,
  leftArrow,
  rightArrow,
  danger,
  onClick,
}) => {

  return (
    <button
      className={`text_button ${className}`}
      style={style}
      onClick={
        onClick
          ? onClick
          : () =>
              navigate(targetRoute, {
                state: { payload: routePayload },
              })
      }
    >
      {leftArrow && (
        <BsArrowLeft
          size={20}
          className={`text_button__arrow ${
            arrowAlwaysVisible ? "always_visible" : ""
          }`}
          color={COLORS.primaryColor}
        />
      )}
      <p className={`text_button__text ${danger ? "danger" : ""}`}>{text}</p>

      {rightArrow && (
        <BsArrowRight
          size={20}
          className={`text_button__arrow ${
            arrowAlwaysVisible ? "always_visible" : ""
          }`}
          color={COLORS.primaryColor}
        />
      )}
    </button>
  );
};

export default TextButton;
