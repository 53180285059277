// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-atolyemiz-index-js": () => import("./../../../src/pages/atolyemiz/index.js" /* webpackChunkName: "component---src-pages-atolyemiz-index-js" */),
  "component---src-pages-ekipmanlar-index-js": () => import("./../../../src/pages/ekipmanlar/index.js" /* webpackChunkName: "component---src-pages-ekipmanlar-index-js" */),
  "component---src-pages-etkinlikler-index-js": () => import("./../../../src/pages/etkinlikler/index.js" /* webpackChunkName: "component---src-pages-etkinlikler-index-js" */),
  "component---src-pages-haberler-index-js": () => import("./../../../src/pages/haberler/index.js" /* webpackChunkName: "component---src-pages-haberler-index-js" */),
  "component---src-pages-home-index-js": () => import("./../../../src/pages/Home/index.js" /* webpackChunkName: "component---src-pages-home-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-page-404-index-js": () => import("./../../../src/pages/Page404/index.js" /* webpackChunkName: "component---src-pages-page-404-index-js" */),
  "component---src-pages-urunler-index-js": () => import("./../../../src/pages/urunler/index.js" /* webpackChunkName: "component---src-pages-urunler-index-js" */),
  "component---src-templates-single-news-index-js": () => import("./../../../src/templates/SingleNews/index.js" /* webpackChunkName: "component---src-templates-single-news-index-js" */)
}

