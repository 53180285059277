import React, { useState } from "react";
import Modal from "../Modal";
import { sendRequestEmail } from "../../utils/sendEmail";
import CtaButton from "../Buttons/CtaButton";
import TextButton from "../Buttons/TextButton";
import useIsMobile from "../../hooks/useIsMobile";
import ReactModal from "react-modal";

ReactModal.setAppElement("#___gatsby");

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
  overlay: {
    zIndex: 9,
  },
};

const EventRequestForm = ({ isOpen, closeForm }) => {
  const isMobile = useIsMobile();

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [explanation, setExplanation] = useState("");

  const [error, setError] = useState("");
  const [errorList, setErrorList] = useState(null);

  const [successMessage, setSuccessMessage] = useState("");

  const reset = () => {
    setName("");
    setEmail("");
    setSubject("");
    setExplanation("");
    setError("");
    setErrorList(null);
    setSuccessMessage("");
  };

  const validateForm = () => {
    let errors = {
      name: false,
      email: false,
      subject: false,
      explanation: false,
    };

    let errorFlag = false;

    if (name.length === 0) {
      errors.name = true;
      errorFlag = true;
    }

    if (email.length === 0) {
      errors.email = true;
      errorFlag = true;
    }

    if (subject.length === 0) {
      errors.subject = true;
      errorFlag = true;
    }

    if (explanation.length === 0) {
      errors.explanation = true;
      errorFlag = true;
    }

    if (errorFlag) {
      setErrorList(errors);
      setError("Lütfen zorunlu alanları doldurun.");
      return false;
    } else {
      setErrorList(null);
      setError("");
      return true;
    }
  };

  const submitHandler = async () => {
    try {
      let validationResult = validateForm();
      if (!validationResult) return;

      await sendRequestEmail({
        name,
        email,
        subject,
        explanation,
      });

      setSuccessMessage("Başvurunuz alınmıştır.");
      setTimeout(() => {
        reset();
        closeForm();
      }, 1500);
    } catch {
      setError("Bir hata oluştu. Lütfen daha sonra tekrar deneyiniz.");
    }
  };

  const cancelHandler = () => {
    reset();
    closeForm();
  };

  return (
    <ReactModal isOpen={isOpen} style={customStyles}>
      <div className="request_form_container">
        {!isMobile ? <h1>Talep Formu</h1> : undefined}
        <form>
          <label htmlFor="nameInput">İsim: </label>
          <input
            id="nameInput"
            className={`${errorList?.name && "error"}`}
            maxLength={25}
            type="text"
            value={name}
            onChange={e => setName(e.target.value)}
            required
          />

          <label htmlFor="emailInput">Email: </label>
          <input
            id="emailInput"
            className={`${errorList?.email && "error"}`}
            maxLength={30}
            type="text"
            value={email}
            onChange={e => setEmail(e.target.value)}
            required
          />

          <label htmlFor="additionalInformationInput">Talep Konusu: </label>
          <input
            id="additionalInformationInput"
            className={`${errorList?.subject && "error"}`}
            maxLength={200}
            type="text"
            value={subject}
            onChange={e => setSubject(e.target.value)}
            required
          />

          <label htmlFor="additionalInformationInput">Talep Açıklaması: </label>
          <textarea
            id="additionalInformationInput"
            className={`${errorList?.explanation && "error"}`}
            maxLength={200}
            type="text"
            value={explanation}
            onChange={e => setExplanation(e.target.value)}
            required
          />
        </form>

        <div className="notifier_container">
          {error ? (
            <p className="error_notifier">{error}</p>
          ) : (
            <p className="success_notifier">{successMessage}</p>
          )}
        </div>

        <CtaButton onClick={submitHandler} text="Talebi Yolla" />
        <TextButton text="Vazgeç" danger onClick={cancelHandler} />
      </div>
    </ReactModal>
  );
};

export default EventRequestForm;
