import React from "react";
import { navigate } from "gatsby";

const CtaButton = ({
  text,
  wide,
  secondary,
  danger,
  style,
  targetRoute,
  noClick,
  onClick
}) => {
  return (
    <button
      className={`cta_button ${wide ? "wide" : ""} ${
        secondary ? "secondary" : ""
      } ${danger ? "danger" : ""}`}
      style={style}
      onClick={noClick ? null : onClick ? onClick : () => navigate(targetRoute)}
    >
      {text}
    </button>
  );
};

export default CtaButton;
