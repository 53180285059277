import React, { useState } from "react";
import mainLogo from "../../images/logos/3-atolye-ankara-logo.png";
import { Link } from "gatsby";
import TextButton from "../Buttons/TextButton";
import useScrollTracker from "../../hooks/useScrollTracker";

const MenuButton = ({ onClick, isOpen }) => {
  return (
    <button className={`menu_button ${isOpen && "open"}`} onClick={onClick}>
      <div className="menu_button__line"></div>
      <div className="menu_button__line"></div>
      <div className="menu_button__line"></div>
    </button>
  );
};

const Navbar = ({ openRequestForm }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const scrollPosition = useScrollTracker();

  const toggleMenu = () => {
    setIsMenuOpen(prev => !prev);
  };

  const openRequestFormHandler = () => {
    toggleMenu();
    openRequestForm();
  };

  return (
    <header
      id="navbar"
      className={`${scrollPosition >= 150 ? "collapsed sticky" : ""} ${
        isMenuOpen ? "menu_open" : ""
      }`}
    >
      <div className="logo_container">
        <Link
          style={{
            width: "100%",
            height: "100%",
          }}
          to="/"
        >
          <img src={mainLogo} alt="Atölye Ankara Logosu" />
        </Link>
      </div>
      <nav
        className={`${isMenuOpen ? "menu_open" : ""} ${
          scrollPosition >= 150 ? "collapsed" : ""
        }`}
      >
        <ul>
          <li className="navbar_item">
            <Link onClick={toggleMenu} to="/">
              Anasayfa
            </Link>
          </li>
          <li className="navbar_item">
            <Link onClick={toggleMenu} to="/atolyemiz">
              Atölyemiz
            </Link>
          </li>
          <li className="navbar_item">
            <Link onClick={toggleMenu} to="/urunler">
              Ürünler
            </Link>
          </li>
          <li className="navbar_item">
            <Link onClick={toggleMenu} to="/ekipmanlar">
              Ekipmanlar
            </Link>
          </li>
          <li className="navbar_item">
            <Link onClick={toggleMenu} to="/haberler">
              Haberler
            </Link>
          </li>
          <li className="navbar_item">
            <Link onClick={toggleMenu} to="/etkinlikler">
              Etkinlikler
            </Link>
          </li>
          <TextButton
            onClick={openRequestFormHandler}
            text="Talep Formu"
            style={{
              borderRadius: "15px",
            }}
          />
        </ul>
      </nav>

      <MenuButton onClick={toggleMenu} isOpen={isMenuOpen} />
    </header>
  );
};

export default Navbar;
