import emailjs from "emailjs-com";

export const sendEmail = async info => {
  const {
    name,
    email,
    additionalInformation,
    eventName,
  } = info;

  try {
    const body =
          {
            isim: name,
            etkinlik_ismi: eventName,
            email,
            ekstra_not: additionalInformation,
          };

    const result = await emailjs.send(
      process.env.GATSBY_EMAILJS_SERVICE_ID,
      process.env.GATSBY_EMAILJS_KATILIM_TEMPLATE_ID,
      body,
      process.env.GATSBY_EMAILJS_USER_ID
    );

    console.log("Email result: ", result);
    Promise.resolve();
  } catch (err) {
    console.error("Email error: ", err);
    Promise.reject(err);
  }
};


export const sendRequestEmail = async info => {
  const {
    name,
    email,
    subject,
    explanation,
  } = info;

  try {
    const body =
          {
            isim: name,
            email,
            talep_konu: subject,
            talep_aciklama: explanation,
          };

    const result = await emailjs.send(
      process.env.GATSBY_EMAILJS_SERVICE_ID,
      process.env.GATSBY_EMAILJS_TALEP_TEMPLATE_ID,
      body,
      process.env.GATSBY_EMAILJS_USER_ID
    );

    console.log("Email result: ", result);
    Promise.resolve();
  } catch (err) {
    console.error("Email error: ", err);
    Promise.reject(err);
  }
};
